import type { LinkNumberType, SendersIdType } from '@/definitions/services/byoc/types'

export const ProviderListMock = [
  {
    provider: {
      logo: require('@/assets/images/byoc/short-logos/vonage.png'),
      name: 'Vonage test',
      link: { name: 'base.chats.settings.byoc.details.vonage' },
    },
    linkedNumbers: '4',
    accountId: 'VASF333FA3caa3add57a353',
    status: {
      color: 'green',
      outline: true,
      name: 'connected',
    },
    date: '8 Jul, 10:44 am',
  },
  {
    provider: {
      logo: require('@/assets/images/byoc/short-logos/twilio.svg'),
      name: 'Twilio Production',
      link: { name: 'base.chats.settings.byoc.details.twilio' },
    },
    linkedNumbers: '3',
    accountId: 'TC1d762f22a6a055f56b3caa3add57a353',
    status: {
      color: 'green',
      outline: true,
      name: 'connected',
    },
    date: '4 Jun 7:00 am',
  },
  {
    provider: {
      logo: require('@/assets/images/byoc/short-logos/sinch.png'),
      name: 'Sinch',
      link: { name: 'base.chats.settings.byoc.details.sinch' },
    },
    linkedNumbers: '1',
    accountId: '33FA3cVASF3aa3a35add5738',
    status: {
      color: 'green',
      outline: true,
      name: 'connected',
    },
    date: '2 Jun 9:12 am',
  },
  {
    provider: {
      logo: require('@/assets/images/byoc/short-logos/infobip.svg'),
      name: 'Infobip',
      link: { name: 'base.chats.settings.byoc.details.infobip' },
    },
    linkedNumbers: '7',
    accountId: '545ALId462ss7',
    status: {
      color: 'green',
      outline: true,
      name: 'connected',
    },
    date: '6 Aug, 9:12 am',
  },
  {
    provider: {
      logo: require('@/assets/images/byoc/short-logos/bandwidth.png'),
      name: 'Bandwidth',
      link: { name: 'base.chats.settings.byoc.details.bandwidth' },
    },
    linkedNumbers: '2',
    accountId: 'AC1d762f22a6a055f56b3caa3add57a353',
    status: {
      color: 'green',
      outline: true,
      name: 'connected',
    },
    date: '8 Jul, 10:44 am',
  },
]

export const NumbersMock: LinkNumberType[] = [
  {
    id: 1,
    number: {
      id: 'us',
      name: '(345) 434-7654',
    },
    label: 'Marketing USA',
    voice: true,
    sms: true,
    mms: false,
    provider: {
      logo: require('@/assets/images/byoc/short-logos/twilio.svg'),
      name: 'Twilio',
    },
    status: {
      name: 'linked',
      color: 'green',
      outline: true,
    },
  },
  {
    id: 2,
    number: {
      id: 'uk',
      name: '+44 7355 577146',
    },
    label: '',
    voice: false,
    sms: true,
    mms: false,
    provider: {
      logo: require('@/assets/images/byoc/short-logos/sinch.png'),
      name: 'Sinch',
    },
    status: {
      name: 'linked',
      color: 'green',
      outline: true,
    },
    forward: 'Not supported',
  },
  {
    id: 3,
    number: {
      id: 'us',
      name: '(201) 555-0124',
    },
    label: 'Support Australia',
    voice: true,
    sms: true,
    mms: true,
    provider: {
      logo: require('@/assets/images/byoc/short-logos/vonage.png'),
      name: 'Vonage test',
    },
    status: {
      name: 'linked',
      color: 'green',
      outline: true,
    },
    forward: 'Not supported',
  },
  {
    id: 4,
    number: {
      id: 'us',
      name: '(302) 555-0107',
    },
    label: 'Support',
    voice: true,
    sms: false,
    mms: false,
    provider: {
      logo: require('@/assets/images/byoc/short-logos/infobip.svg'),
      name: 'Infobip',
    },
    status: {
      name: 'linked',
      color: 'green',
      outline: true,
    },
    forward: 'Not supported',
  },
  {
    id: 5,
    number: {
      id: 'us',
      name: '(702) 555-0122',
    },
    label: '',
    voice: false,
    sms: true,
    mms: true,
    provider: {
      logo: require('@/assets/images/byoc/short-logos/twilio.svg'),
      name: 'Twilio',
    },
    status: {
      name: 'linked',
      color: 'green',
      outline: true,
    },
    forward: 'Not supported',
  },
  {
    id: 6,
    number: {
      id: 'us',
      name: '(207) 555-0119',
    },
    label: 'UK Support',
    voice: true,
    sms: false,
    mms: false,
    provider: {
      logo: require('@/assets/images/byoc/short-logos/twilio.svg'),
      name: 'Twilio',
    },
    status: {
      name: 'linked',
      color: 'green',
      outline: true,
    },
    forwardNumber: {
      id: 'us',
      name: '+44 1833 657665',
    },
  },
]

export const NumbersMockWithNoLinked: LinkNumberType[] = NumbersMock.map((e: any) => {
  if (e.label === 'Support Australia') {
    return {
      ...e,
      disable: e.status.name === 'linked',
    }
  } else {
    return {
      ...e,
      status: {
        name: 'not linked',
        color: 'orange',
        outline: true,
      },
    }
  }
})

export const SendersIdMock: SendersIdType[] = [
  {
    title: 'Wonderful Texts',
    voice: false,
    sms: true,
    mms: false,
    provider: {
      logo: require('@/assets/images/byoc/short-logos/twilio.svg'),
      name: 'Twilio Production',
    },
    status: {
      name: 'not linked',
      color: 'orange',
      outline: true,
    },
  },
  {
    title: 'Chic Texts',
    voice: false,
    sms: true,
    mms: false,
    provider: {
      logo: require('@/assets/images/byoc/short-logos/twilio.svg'),
      name: 'Twilio',
    },
    status: {
      name: 'linked',
      color: 'green',
      outline: true,
    },
  },
  {
    title: 'HelloTexts',
    voice: false,
    sms: true,
    mms: false,
    provider: {
      logo: require('@/assets/images/byoc/short-logos/twilio.svg'),
      name: 'Twilio',
    },
    status: {
      name: 'not linked',
      color: 'orange',
      outline: true,
    },
  },
]
