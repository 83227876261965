import { useModals } from '@/compositions/modals'

type UseByocType = () => {
  openUnlinkNumberModal: () => void;
  openDisconnectProviderModal: () => void;
  openEditProviderLabelModal: (label: string, logo: string | undefined) => void;
  openEditProviderLoginModal: () => void;
  openEditProviderLoginKeyIdModal: () => void;
  openEditProviderLoginPasswordModal: () => void;
  openEditProviderLoginApiModal: () => void;
  openEditLabelModal: (title?: string) => void;
  openCancelNumberModal: () => void;
  openCallbackUrlModal: () => void;
}

export const useByoc: UseByocType = () => {
  const { openModal } = useModals()
  const openCancelNumberModal = () => {
    openModal('confirmation', {
      title: 'Cancel number',
      btnText: 'Cancel number',
      btnColor: 'error',
      cancelButtonText: 'Discard',
      text: [
        'Are you sure you want to cancel the number ',
        {
          text: '(808) 128-1088',
          style: 'semi-bold',
        },
        '?\n\n If you continue, you will be able to use this number until ',
        {
          text: '10 Mar 2022.',
          style: 'semi-bold',
        },
        ' After this date you will not be able to use it for communication anymore and the number cannot be restored.',
      ],
    })
  }

  const openEditLabelModal = (title?: string) => {
    openModal('servicesByocLabel', {
      modalTitle: typeof title === 'string' ? title : 'Edit number label',
      btnText: 'Save',
    })
  }

  const openEditProviderLabelModal = (label: string, logo: string | undefined) => {
    openModal('servicesByocLabel', {
      modalTitle: 'Edit provider label',
      btnText: 'Save',
      itemImage: logo,
      label: label,
      itemLabel: 'Provider',
    })
  }

  const openEditProviderLoginModal = () => {
    openModal('servicesByocLogin')
  }

  const openEditProviderLoginKeyIdModal = () => {
    openModal('servicesByocLoginKeyId')
  }

  const openEditProviderLoginPasswordModal = () => {
    openModal('servicesByocLoginPassword')
  }

  const openEditProviderLoginApiModal = () => {
    openModal('servicesByocLoginApi')
  }

  const openUnlinkNumberModal = () => {
    openModal('confirmation', {
      title: 'Unlink number',
      text: 'Are you sure that you want to disconnect this virtual number? You will not be able to communicate using this number anymore.',
      btnText: 'Unlink number',
      btnColor: 'red',
    })
  }

  const openDisconnectProviderModal = () => {
    openModal('confirmation', {
      title: 'Disconnect provider',
      text: 'Are you sure that you want to disconnect this CPaaS provider? \nAll related virtual numbers will be unlinked from Textmagic.',
      btnText: 'Disconnect provider',
      btnColor: 'red',
    })
  }

  const openCallbackUrlModal = () => {
    openModal('callbackUrl')
  }

  return {
    openEditProviderLabelModal,
    openEditProviderLoginModal,
    openEditProviderLoginKeyIdModal,
    openEditProviderLoginPasswordModal,
    openEditProviderLoginApiModal,
    openUnlinkNumberModal,
    openCancelNumberModal,
    openDisconnectProviderModal,
    openEditLabelModal,
    openCallbackUrlModal,
  }
}
