
import { defineComponent, ref } from 'vue'
import TmModal from '@/components/shared/modal/TmModal.vue'
import TmButton from '@/components/shared/TmButton.vue'
import LinkNumbersTable from '@/components/pages/chats/settings/channels/flow/linkNumbers/LinkNumbersTable.vue'
import type { TableHeaders } from '@/definitions/shared/types'
import type { LinkNumberType } from '@/definitions/services/byoc/types'
import { NumbersMock } from '@/definitions/services/byoc/data'

export default defineComponent({
  components: { TmButton, TmModal, LinkNumbersTable },
  props: {
    modalTitle: {
      type: String,
      default: 'Link numbers',
    },
    subtitle: {
      type: String,
      default: 'Please select the numbers that you would like to link to Textmagic.',
    },
  },
  setup() {
    const tableHeaders = ref<TableHeaders[]>([
      { text: 'Number', value: 'number-slot' },
      { text: 'Label', value: 'label-slot' },
      { text: 'Voice', value: 'voice-slot', width: '80px' },
      { text: 'SMS', value: 'sms-slot', width: '80px' },
      { text: 'MMS', value: 'mms-slot', width: '160px' },
      { text: 'Status', value: 'status-slot' },
    ])
    const tableItems = ref(NumbersMock.map((e: LinkNumberType) => {
      return {
        ...e,
        status: {
          name: 'not linked',
          color: 'orange',
          outline: true,
        },
      }
    }))

    const selected = ref([])

    return {
      tableHeaders,
      selected,
      tableItems,
    }
  },
})
