
import type { PropType } from 'vue'
import { defineComponent } from 'vue'
import TmTable from '@/components/shared/table/TmTable.vue'
import TmCountry from '@/components/shared/TmCountry.vue'
import TmButton from '@/components/shared/TmButton.vue'
import LinkNumbersTableDropdown from '@/components/pages/chats/settings/channels/flow/linkNumbers/LinkNumbersTableDropdown.vue'
import TmTooltip from '@/components/shared/TmTooltip.vue'
import TmBadge from '@/components/shared/TmBadge.vue'
import TmStatus from '@/components/shared/TmStatus.vue'
import Highlight from '@/components/shared/Highlight.vue'
import type { TableHeaders } from '@/definitions/shared/types'
import { useModals } from '@/compositions/modals'
import { useByoc } from '@/compositions/services/useByoc'
import type { RouteLocationRaw } from 'vue-router'

export default defineComponent({
  components: {
    TmTable,
    TmTooltip,
    TmButton,
    LinkNumbersTableDropdown,
    TmBadge,
    TmStatus,
    TmCountry,
    Highlight,
  },
  props: {
    headers: {
      type: Array as PropType<TableHeaders[]>,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
    itemsName: {
      type: String,
      required: true,
    },
    showLink: {
      type: Boolean,
    },
    link: {
      type: Object as PropType<RouteLocationRaw>,
      default: () => ({ name: 'base.chats.settings.byoc.details' }),
    },
    showSelection: {
      type: Boolean,
      default: true,
    },
    disableEditLabel: {
      type: Boolean,
    },
    search: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const { openEditLabelModal, openUnlinkNumberModal } = useByoc()
    const { openModal } = useModals()

    const handleEditClick = () => {
      if (!props.disableEditLabel) {
        openEditLabelModal()
      }
    }

    return {
      openModal,
      openUnlinkNumberModal,
      handleEditClick,
    }
  },
})
