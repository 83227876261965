import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "body-text-regular-14 mb-4 lh-20 neutral--text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_link_numbers_table = _resolveComponent("link-numbers-table")!
  const _component_tm_button = _resolveComponent("tm-button")!
  const _component_tm_modal = _resolveComponent("tm-modal")!

  return (_openBlock(), _createBlock(_component_tm_modal, {
    id: "servicesByocLinkNumbers",
    "modal-title": _ctx.modalTitle,
    size: "xLarge"
  }, {
    "modal-content": _withCtx(() => [
      _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.subtitle), 1),
      _createVNode(_component_link_numbers_table, {
        modelValue: _ctx.selected,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selected) = $event)),
        headers: _ctx.tableHeaders,
        "disable-edit-label": "",
        items: _ctx.tableItems,
        "hide-actions": "",
        "items-name": "numbers"
      }, null, 8, ["modelValue", "headers", "items"])
    ]),
    "modal-footer-button": _withCtx(() => [
      _createVNode(_component_tm_button, {
        color: "primary",
        size: "large",
        disable: !_ctx.selected.length
      }, {
        default: _withCtx(() => [
          _createTextVNode(" Link " + _toDisplayString(_ctx.selected.length) + " numbers ", 1)
        ]),
        _: 1
      }, 8, ["disable"])
    ]),
    _: 1
  }, 8, ["modal-title"]))
}